/** @jsx jsx */ /** @jsxRuntime classic */ import { jsx } from "theme-ui";
import { Parallax } from 'react-spring/renderprops-addons';
import { Global } from '@emotion/react';
import Hero from './sections/Hero';
import { ThemeProvider, BaseStyles } from 'theme-ui';
import theme from './theme';
import Second from './sections/Second';
import Third from './sections/Third';
import Ending from './sections/Ending';
import 'react-vis/dist/style.css'

function App() {
  return (
    <ThemeProvider theme={theme}>
      <BaseStyles>
        <Global
          styles={{
            "*": {
              boxSizing: `inherit`,
              "&:before": {
                boxSizing: `inherit`,
              },
              "&:after": {
                boxSizing: `inherit`,
              },
            },
            html: {
              fontSize: `18px`,
              WebkitTextSizeAdjust: `100%`,
            },
            img: {
              borderStyle: `none`,
            },
            pre: {
              fontFamily: `monospace`,
              fontSize: `1em`,
            },
            "[hidden]": {
              display: `none`,
            },
            "::selection": {
              backgroundColor: theme.colors.primary,
              color: theme.colors.background,
            },
          }}
        />
        <Parallax pages={5}>
          <Hero offset={0} factor={2} />
          <Second offset={1} factor={2} />
          <Third offset={3} factor={1} />
          <Ending offset={4} factor={1} />
          <footer
            sx={{
              textAlign: `center`,
              display: `block`,
              position: `absolute`,
              bottom: `1em`,
              width: `100%`,
              color: `textMuted`
            }}
          >
            Thanks for reading.
          </footer>
        </Parallax>
      </BaseStyles>
    </ThemeProvider>
  );
}

export default App;
