/** @jsx jsx */ /** @jsxRuntime classic */ import { jsx, Styled } from "theme-ui";
import { UpDown, UpDownWide } from "../elements/Animations";
import Content from "../elements/Content";
import Divider from "../elements/Divider";
import Inner from "../elements/Inner";
import SVG from "../elements/SVG";

function Third(props) {
  return (
    <div>
      <Divider
        bg="divider"
        clipPath="polygon(0 16%, 100% 4%, 100% 82%, 0 94%)"
        speed={0.2}
        offset={props.offset}
        factor={props.factor}
      />
      <Divider speed={0.1} offset={props.offset} factor={props.factor}>
        <UpDown>
          <SVG icon="box" hiddenMobile width={6} color="icon_blue" left="50%" top="75%" />
          <SVG icon="upDown" hiddenMobile width={8} color="icon_darkest" left="70%" top="20%" />
          <SVG icon="triangle" width={8} stroke color="icon_darkest" left="25%" top="5%" />
          <SVG icon="upDown" hiddenMobile width={24} color="icon_orange" left="80%" top="80%" />
        </UpDown>
        <UpDownWide>
          <SVG icon="arrowUp" hiddenMobile width={16} color="icon_purple" left="5%" top="80%" />
          <SVG icon="triangle" width={12} stroke color="icon_brightest" left="95%" top="50%" />
          <SVG icon="circle" hiddenMobile width={6} color="icon_brightest" left="85%" top="15%" />
          <SVG icon="upDown" hiddenMobile width={8} color="icon_darkest" left="45%" top="10%" />
        </UpDownWide>
        <SVG icon="circle" hiddenMobile width={6} color="icon_brightest" left="4%" top="20%" />
        <SVG icon="circle" width={12} color="icon_darkest" left="70%" top="60%" />
        <SVG icon="box" width={6} color="icon_orange" left="10%" top="10%" />
        <SVG icon="box" width={12} color="icon_darkest" left="20%" top="30%" />
        <SVG icon="hexa" width={8} stroke color="icon_darkest" left="80%" top="70%" />
      </Divider>
      <Content speed={0.4} offset={props.offset} factor={props.factor}>
        <Inner>
          <Styled.h2>Third thing</Styled.h2>
          <Styled.p>Still not really getting any styling though</Styled.p>
        </Inner>
      </Content>
    </div>
  );
}

Third.defaultProps = {
  factor: 1
};

export default Third;
