/** @jsx jsx */ /** @jsxRuntime classic */ import { jsx } from "theme-ui";

function Inner(props) {
  return (
    <div
      sx={{
        width: [`full`, `full`, `full`, `full`, `full`, `2/3`],
        textAlign: `left`,
      }}
      className={props.className}
    >
      {props.children}
    </div>
  );
}

Inner.defaultProps = {
  className: '',
};

export default Inner;
