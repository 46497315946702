/** @jsx jsx */ /** @jsxRuntime classic */ import { jsx, Styled } from "theme-ui";
import { UpDown, UpDownWide } from "../elements/Animations";
import Content from "../elements/Content";
import Divider from "../elements/Divider";
import formatDuration from "../elements/Duration";
import Inner from "../elements/Inner";
import SVG from "../elements/SVG";
import DurationByDayChart from "../facts/DurationByDayChart";

function Second(props) {
  return (
    <div>
      <Divider
        bg="linear-gradient(to right, SlateBlue 0%, DeepSkyBlue 100%)"
        sx={{ clipPath: `polygon(0 15%, 100% 25%, 100% 85%, 0 75%)` }}
        speed={-0.2}
        offset={1.1}
        factor={props.factor}
      />
      <Content speed={0.4} offset={props.offset + 0.2} factor={props.factor}>
        <Inner>
          <div
            sx={{
              display: `grid`,
              gridGap: [4, 4, 4, 5],
              gridTemplateColumns: [`1fr`, `1fr`, `repeat(2, 1fr)`],
              h2: { gridColumn: `-1/1`, color: `white !important` },
            }}
          >
          <Styled.h2>Overall stats</Styled.h2>
          <Styled.p>
            <b>10</b> users watched<br/>
              <b>{formatDuration(363574)}</b> of movies,<br/>
              <b>{formatDuration(4162589)}</b> of TV<br />
            <br />
            That's over <b>6.5 times the length of the Apollo 11 mission</b>!
          </Styled.p>
          <Styled.p><DurationByDayChart width={`350`} height={`350`} /></Styled.p>
          </div>
        </Inner>
      </Content>
      <Divider speed={0.1} offset={props.offset} factor={props.factor}>
        <UpDown>
          <SVG icon="box" width={6} color="icon_brightest" left="85%" top="75%" />
          <SVG icon="upDown" width={8} color="icon_teal" left="70%" top="20%" />
          <SVG icon="triangle" width={8} stroke color="icon_orange" left="25%" top="5%" />
          <SVG icon="circle" hiddenMobile width={24} color="icon_brightest" left="17%" top="60%" />
        </UpDown>
        <UpDownWide>
          <SVG icon="arrowUp" hiddenMobile width={16} color="icon_green" left="20%" top="90%" />
          <SVG icon="triangle" width={12} stroke color="icon_brightest" left="90%" top="30%" />
          <SVG icon="circle" width={16} color="icon_yellow" left="70%" top="90%" />
          <SVG icon="triangle" hiddenMobile width={16} stroke color="icon_teal" left="18%" top="75%" />
          <SVG icon="circle" width={6} color="icon_brightest" left="75%" top="10%" />
          <SVG icon="upDown" hiddenMobile width={8} color="icon_green" left="45%" top="10%" />
        </UpDownWide>
        <SVG icon="circle" hiddenMobile width={6} color="icon_brightest" left="4%" top="20%" />
        <SVG icon="circle" width={12} color="icon_pink" left="80%" top="60%" />
        <SVG icon="box" width={6} color="icon_orange" left="10%" top="10%" />
        <SVG icon="box" width={12} color="icon_yellow" left="29%" top="26%" />
        <SVG icon="hexa" width={16} stroke color="icon_red" left="75%" top="30%" />
        <SVG icon="hexa" width={8} stroke color="icon_yellow" left="80%" top="70%" />
      </Divider>
    </div>
  )
}

Second.defaultProps = {
  factor: 1,
}

export default Second;
