const humanizeDuration = require("humanize-duration").humanizer({
  units: ["y", "mo", "d", "h", "m"],
  maxDecimalPoints: 0
});

function formatDuration(durationInSeconds) {
  return humanizeDuration(durationInSeconds * 1000);
}

export default formatDuration;
