/** @jsx jsx */ /** @jsxRuntime classic */ import { jsx } from "theme-ui";

import { ParallaxLayer } from "react-spring/renderprops-addons";

function Divider(props) {
  return (
    <ParallaxLayer
      sx={{
        position: `absolute`,
        width: `full`,
        height: `full`,
        background: props.bg,
        backgroundColor: props.bg,
        "#contact-wave": {
          color: props.fill,
          fill: `currentColor`,
        },
        clipPath: props.clipPath,
      }}
      speed={props.speed}
      offset={props.offset}
      factor={props.factor}
      className={props.className}
    >
      {props.children}
    </ParallaxLayer>
  );
}

Divider.defaultProps = {
  factor: 1,
  bg: '',
  fill: '',
  clipPath: '',
  children: null,
  className: '',
};

export default Divider;
