/** @jsx jsx */ /** @jsxRuntime classic */ import { jsx } from "theme-ui";

import { ParallaxLayer } from "react-spring/renderprops-addons"

function Content(props) {
  return (
    <ParallaxLayer
      sx={{
        padding: [3, 4, 4, 5],
        display: `flex`,
        flexDirection: `column`,
        alignItems: `center`,
        justifyContent: `center`,
        zIndex: 50,
      }}
      speed={props.speed}
      offset={props.offset}
      factor={props.factor}
      className={props.className}
    >
    {props.children}
    </ParallaxLayer>
  );
}

Content.defaultProps = {
  factor: 1,
  className: '',
};

export default Content;
