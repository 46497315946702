/** @jsx jsx */ /** @jsxRuntime classic */ import { jsx } from "theme-ui";
import React from 'react';
import { Crosshair, HorizontalGridLines, VerticalBarSeries, VerticalGridLines, XAxis, XYPlot } from "react-vis";
import formatDuration from "../elements/Duration";

const DATA = [
  [//TV data
    { x: 'Monday', y: 570340 },
    { x: 'Tuesday', y: 455636 },
    { x: 'Wednesday', y: 523088 },
    { x: 'Thursday', y: 642750 },
    { x: 'Friday', y: 500421 },
    { x: 'Saturday', y: 741340 },
    { x: 'Sunday', y: 733447 }
  ],
  [//Movie data
    { x: 'Monday', y: 31455 },
    { x: 'Tuesday', y: 23893 },
    { x: 'Wednesday', y: 45738 },
    { x: 'Thursday', y: 30762 },
    { x: 'Friday', y: 101060 },
    { x: 'Saturday', y: 75254 },
    { x: 'Sunday', y: 55412 },
  ]
];

export default class DurationByDayChart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      crosshairValues: []
    };
  }

  _onMouseLeave = () => {
    this.setState({crosshairValues: []});
  }

  _onNearestX = (value, {index}) => {
    this.setState({crosshairValues: DATA.map(d => d[index])});
  }

  render() {
    return (
      <XYPlot
        margin={{ bottom: 70 }}
        width={this.props.width}
        height={this.props.height}
        stackBy="y"
        xType="ordinal"
        onMouseLeave={this._onMouseLeave}
      >
        <VerticalGridLines />
        <HorizontalGridLines />
        <XAxis
          tickLabelAngle={-60}
          style={{
            text: { fontSize: `12px`, fill: `#e2e8f0` }
          }}
        />

        <VerticalBarSeries 
          data={DATA[0]}
          onNearestX={this._onNearestX}
          color={`#ecc94b`}
        />
        <VerticalBarSeries
          data={DATA[1]}
          color={`#805ad5`}
        />
        <Crosshair 
          values={this.state.crosshairValues}
          titleFormat={(d) => ({title: 'Day', value: d.[0].x})}
          itemsFormat={(d) => (
            [
              { title: 'Movies', value: formatDuration(d.[1].y) },
              { title: 'TV', value: formatDuration(d.[0].y) }
            ]
          )}
        >
        </Crosshair>
      </XYPlot>
    );
  }
}

DurationByDayChart.defaultProps = {
  width: 300,
  height: 300,
}
